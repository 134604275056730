import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import { reloadCart, addQuantity, removeQuantity, deleteProduct, addProduct, resetCart} from '@/helper/cartStore';
Vue.use(Vuex);

const logout = (state) => {
    localStorage.removeItem('user');
    localStorage.removeItem('fsession');
    state.user = null;
    window.location = '/login';
}

export default new Vuex.Store({
    state: {
        cart: [],
        user: null,
        cookies: false
    },
    getters: {
        getCart (state) {
          return state.cart
        }
    },
    mutations: {
        addQuantity: addQuantity,
        removeQuantity: removeQuantity,
        deleteProduct: deleteProduct,
        reloadCart: reloadCart,
        addProduct: addProduct,
        resetCart: resetCart,
        login: (state, connections) => {
            localStorage.setItem('user', connections.token);
            localStorage.setItem('fsession', connections.f);
            state.user = connections.token;
            const fin =  connections.f - Math.round(new Date().getTime() / 1000);
            setTimeout(()=>{
                logout(state);
            }, fin * 1000);
        },
        logout: logout,
        aceptCookies: (state) => {
            localStorage.setItem('cookies', true);
            state.cookies = true;
        },
        initialStore(state){
            if(localStorage.getItem('cart') && (state.cart = JSON.parse(localStorage.getItem('cart')))){
                reloadCart(state);
            }else{
                state.cart = [];
            }
            if(localStorage.getItem('user') && localStorage.getItem('user') != null){
                state.user = localStorage.getItem('user');
                axios
                .get(process.env.VUE_APP_URL+'my_user')
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        state.user = localStorage.getItem('user');
                    else{
                        state.user = null;
                        window.location = '/login';
                    }
                })
                .catch(error => {
                    state.user = null;
                    localStorage.removeItem('user');
                    window.location = '/login';
                });
                if(localStorage.getItem("fsession")){
                    const fin = localStorage.getItem("fsession") - Math.round(new Date().getTime() / 1000);
                    setTimeout(()=>{
                        logout(state);
                    }, fin * 1000);
                }
            }
            if(localStorage.getItem('cookies'))
                state.cookies = localStorage.getItem('cookies');
        },
    }
});