<template>
    <div>
        <header class="header">
            <div class="nav_search" :class="{reveal: viewBuscar}">
                <div class="half_inner">
                    <div class="nav_search_wrapper">
                        <div class="nav_search_field">
                            <form>
                                <input @focus="showRelated=true" @blur="focusOut" v-model="busqueda" id="bsqd" type="search" placeholder="Búsqueda" autocomplete="off"/><div class="delete_search_data"></div>
                            </form>
                            <div class="nav_search_list"
                            v-if="(resultBusqueda.productos.length > 0 || resultBusqueda.artistas.length > 0 || resultBusqueda.categorias.length > 0) && busqueda.length > 0 && showRelated"
                            :style="{display: busqueda.length > 0 ? 'block' : 'none'}">
                                <div class="search_family_label" v-if="resultBusqueda.productos.length > 0">Productos</div>
                                <router-link :to="`/productos/${x.url}`"
                                class="nav_search_list_result"
                                v-for="x in resultBusqueda.productos"
                                :key="x.id+Math.random()">
                                    <b>{{x.name}}</b>
                                    <span>/</span>
                                    <span v-for="categoria in x.categories" :key="categoria.id+Math.random()">
                                        {{categoria.name}}
                                    </span>
                                </router-link>
                                <div class="search_family_label" v-if="resultBusqueda.artistas.length > 0">Artistas</div>
                                <router-link :to="`/artistas/${x.url}`"
                                class="nav_search_list_result"
                                v-for="x in resultBusqueda.artistas"
                                :key="x.id+Math.random()">
                                    <b>{{x.name}}</b>
                                    <span>/</span>
                                    <span>ARTISTA</span>
                                </router-link>
                                <div class="search_family_label" v-if="resultBusqueda.categorias.length > 0">Categorias</div>
                                <router-link :to="`/productos?category=${x.id}`"
                                class="nav_search_list_result"
                                v-for="x in resultBusqueda.categorias"
                                :key="x.id+Math.random()">
                                    <b>{{x.name}}</b>
                                    <span>/</span>
                                    <span>CATEGORIA</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header_wrapper">
                <div class="logo_wrapper">
                    <router-link to="/" class="logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="148" height="24" viewBox="0 0 148 24">
                        <path class="st01" d="M148.07,12.73c-0.54-0.94-1.21-1.6-2.01-1.98c-0.8-0.38-1.68-0.7-2.63-0.97c-0.95-0.26-1.83-0.46-2.63-0.59
                            c-0.8-0.13-1.47-0.37-2.01-0.73c-0.54-0.36-0.81-0.83-0.81-1.41c0-0.58,0.23-1.04,0.68-1.36c0.45-0.33,1.02-0.49,1.71-0.49
                            c0.69,0,1.25,0.14,1.7,0.41c0.15,0.11,0.31,0.24,0.49,0.41c0.18,0.17,0.3,0.28,0.36,0.32c0.65,0.42,1.2,0.63,1.65,0.63
                            c0.76,0,1.5-0.53,2.22-1.59c0.51-0.74,0.76-1.32,0.76-1.74c0-0.42-0.33-0.94-0.98-1.55l-0.79-0.6c-0.4-0.34-1.13-0.67-2.17-1
                            C142.56,0.16,141.4,0,140.13,0c-2.39,0-4.27,0.69-5.66,2.08c-0.31,0.31-0.57,0.62-0.81,0.95c0,0.06,0,0.11,0,0.17v7.93
                            c0.45,0.6,0.96,1.07,1.55,1.39c0.8,0.44,1.67,0.83,2.6,1.16c0.93,0.33,1.8,0.57,2.6,0.71c0.8,0.15,1.47,0.39,2.01,0.71
                            c0.54,0.33,0.81,0.73,0.81,1.22c0,1.61-0.98,2.41-2.95,2.41c-0.46,0-0.97-0.16-1.52-0.49c-0.55-0.33-0.97-0.67-1.27-1.03
                            c-1.06-1.06-1.88-1.59-2.47-1.59c-0.47,0-0.93,0.17-1.36,0.5v4.67c0,0.2,0,0.38-0.01,0.55c0.45,0.43,1.04,0.87,1.78,1.32
                            c0.55,0.36,1.27,0.66,2.16,0.9c0.89,0.24,1.83,0.36,2.82,0.36c1.94,0,3.77-0.56,5.48-1.68c0.89-0.57,1.61-1.38,2.16-2.43
                            c0.55-1.05,0.82-2.26,0.82-3.63C148.88,14.83,148.61,13.67,148.07,12.73z"/>
                        <path class="st02" d="M129.76,1.55c-0.11-0.27-0.25-0.49-0.44-0.63c-0.19-0.15-0.47-0.27-0.82-0.38c-0.36-0.11-0.85-0.16-1.46-0.16
                            c-0.61,0-1.12,0.06-1.52,0.19c-0.4,0.13-0.69,0.35-0.86,0.67c-0.17,0.32-0.27,0.6-0.3,0.84c-0.03,0.24-0.05,0.61-0.05,1.09v6.5h-8.4
                            V3.2c0-0.74-0.05-1.29-0.16-1.65c-0.27-0.78-1.15-1.17-2.63-1.17c-1.35,0-2.18,0.29-2.47,0.86c-0.17,0.32-0.27,0.6-0.3,0.84
                            c-0.03,0.24-0.05,0.61-0.05,1.09V4.7c1.12,2.15,1.68,4.56,1.68,7.21c0,2.69-0.56,5.16-1.68,7.38v1.48c0,0.74,0.06,1.29,0.19,1.65
                            c0.25,0.78,1.12,1.17,2.6,1.17c1.35,0,2.18-0.29,2.47-0.86c0.17-0.32,0.27-0.6,0.3-0.84c0.03-0.24,0.05-0.61,0.05-1.09v-6.47h8.4
                            v6.44c0,0.74,0.06,1.29,0.19,1.65c0.25,0.78,1.12,1.17,2.6,1.17c1.35,0,2.18-0.29,2.47-0.86c0.17-0.32,0.27-0.6,0.3-0.84
                            c0.03-0.24,0.05-0.61,0.05-1.09V3.2C129.92,2.46,129.87,1.91,129.76,1.55z"/>
                        <path class="st03" d="M15.21,20.77v-9.2c-0.34-0.35-0.72-0.62-1.14-0.81c-0.8-0.38-1.68-0.7-2.63-0.97
                            c-0.95-0.26-1.83-0.46-2.63-0.59C8.01,9.07,7.34,8.82,6.8,8.46C6.26,8.11,5.99,7.64,5.99,7.05c0-0.58,0.23-1.04,0.68-1.36
                            C7.13,5.36,7.7,5.2,8.39,5.2c0.69,0,1.25,0.14,1.7,0.41c0.15,0.11,0.31,0.24,0.49,0.41c0.18,0.17,0.3,0.28,0.36,0.32
                            c0.66,0.42,1.2,0.63,1.65,0.63c0.76,0,1.5-0.53,2.22-1.59c0.17-0.24,0.3-0.46,0.41-0.67V3.17c0-0.13,0-0.26,0-0.38
                            c-0.16-0.22-0.37-0.45-0.63-0.7l-0.79-0.6c-0.4-0.34-1.13-0.67-2.17-1C10.57,0.16,9.42,0,8.15,0C5.76,0,3.87,0.69,2.49,2.08
                            C1.1,3.46,0.41,5.08,0.41,6.94c0,1.35,0.27,2.51,0.81,3.47c0.54,0.96,1.21,1.66,2.01,2.11c0.8,0.44,1.67,0.83,2.6,1.16
                            c0.93,0.33,1.8,0.57,2.6,0.71c0.8,0.15,1.47,0.39,2.01,0.71c0.54,0.33,0.81,0.73,0.81,1.22c0,1.61-0.98,2.41-2.95,2.41
                            c-0.47,0-0.97-0.16-1.52-0.49c-0.55-0.33-0.97-0.67-1.27-1.03c-1.06-1.06-1.88-1.59-2.47-1.59c-0.59,0-1.15,0.25-1.68,0.76
                            C0.45,17.28,0,17.99,0,18.53c0,0.54,0.27,1.15,0.82,1.82l0.29,0.35c0.44,0.63,1.23,1.29,2.35,1.97c0.55,0.36,1.27,0.66,2.16,0.9
                            c0.89,0.24,1.83,0.36,2.82,0.36c1.94,0,3.77-0.56,5.48-1.68c0.49-0.31,0.92-0.71,1.31-1.16C15.22,20.98,15.21,20.88,15.21,20.77z"/>
                        <path class="st04" d="M36.9,12.21c0-2.24,0.42-4.38,1.24-6.36c0.14-0.34,0.29-0.66,0.45-0.99V3.2c0-0.74-0.05-1.29-0.16-1.65
                            c-0.11-0.27-0.25-0.49-0.44-0.63c-0.19-0.15-0.47-0.27-0.82-0.38c-0.36-0.11-0.85-0.16-1.46-0.16c-0.61,0-1.12,0.06-1.52,0.19
                            c-0.4,0.13-0.69,0.35-0.86,0.67c-0.17,0.32-0.27,0.6-0.3,0.84c-0.03,0.24-0.05,0.61-0.05,1.09v6.5h-8.4V3.2
                            c0-0.74-0.05-1.29-0.16-1.65c-0.27-0.78-1.15-1.17-2.63-1.17c-1.35,0-2.18,0.29-2.47,0.86c-0.17,0.32-0.27,0.6-0.3,0.84
                            c-0.03,0.24-0.05,0.61-0.05,1.09v17.6c0,0.74,0.06,1.29,0.19,1.65c0.25,0.78,1.12,1.17,2.6,1.17c1.35,0,2.18-0.29,2.47-0.86
                            c0.17-0.32,0.27-0.6,0.3-0.84c0.03-0.24,0.05-0.61,0.05-1.09v-6.47h8.4v6.44c0,0.74,0.06,1.29,0.19,1.65
                            c0.25,0.78,1.12,1.17,2.6,1.17c1.35,0,2.18-0.29,2.47-0.86c0.17-0.32,0.27-0.6,0.3-0.84c0.03-0.24,0.05-0.61,0.05-1.09v-1.46
                            C37.47,17.17,36.9,14.79,36.9,12.21z"/>
                        <path class="st05" d="M61.95,5.69c-0.49-0.78-1.08-1.52-1.77-2.2c-2.3-2.26-5.02-3.39-8.15-3.39c-1.46,0-2.87,0.3-4.25,0.89
                            c-1.37,0.59-2.59,1.41-3.65,2.44c-1.06,1.04-1.9,2.32-2.54,3.85c-0.63,1.53-0.95,3.18-0.95,4.93c0,3.21,1.13,5.98,3.38,8.31
                            C46.27,22.84,49,24,52.22,24c3.21,0,5.92-1.18,8.12-3.53c0.63-0.67,1.16-1.39,1.61-2.15V5.69z M56.21,16.68
                            c-1.2,1.14-2.56,1.71-4.06,1.71c-1.5,0-2.86-0.59-4.07-1.76c-1.22-1.17-1.82-2.7-1.82-4.58c0-1.88,0.6-3.41,1.81-4.58
                            c1.2-1.17,2.56-1.76,4.06-1.76c1.5,0,2.86,0.58,4.07,1.74c1.22,1.16,1.82,2.71,1.82,4.63C58.02,14,57.42,15.54,56.21,16.68z"/>
                        <path class="st06" d="M104.78,3.49c-2.3-2.26-5.02-3.39-8.15-3.39c-1.46,0-2.87,0.3-4.25,0.89c-1.37,0.59-2.59,1.41-3.65,2.44
                            c-0.66,0.64-1.22,1.4-1.72,2.23c0.26,1,0.39,2.06,0.39,3.15c0,1.95-0.41,3.76-1.21,5.39c-0.18,0.36-0.37,0.7-0.58,1.03
                            c0.5,1.96,1.5,3.72,3.01,5.28c2.25,2.32,4.98,3.49,8.2,3.49c3.21,0,5.92-1.18,8.12-3.53c2.2-2.36,3.3-5.21,3.3-8.56
                            C108.24,8.55,107.08,5.75,104.78,3.49z M100.82,16.68c-1.2,1.14-2.56,1.71-4.06,1.71c-1.5,0-2.86-0.59-4.07-1.76
                            c-1.22-1.17-1.82-2.7-1.82-4.58c0-1.88,0.6-3.41,1.81-4.58c1.2-1.17,2.56-1.76,4.06-1.76c1.5,0,2.86,0.58,4.07,1.74
                            c1.22,1.16,1.82,2.71,1.82,4.63C102.62,14,102.02,15.54,100.82,16.68z"/>
                        <path class="st07" d="M82.84,5.07c0.55,1.12,0.82,2.37,0.82,3.74c0,1.37-0.27,2.62-0.82,3.73c-0.55,1.11-1.26,1.99-2.12,2.65
                            c-1.75,1.35-3.57,2.03-5.45,2.03H71.3v3.58c0,0.49-0.02,0.85-0.05,1.09c-0.03,0.24-0.13,0.52-0.3,0.84
                            c-0.3,0.57-1.12,0.86-2.47,0.86c-1.48,0-2.35-0.39-2.6-1.17c-0.13-0.36-0.19-0.91-0.19-1.65V3.17c0-0.49,0.02-0.85,0.05-1.09
                            c0.03-0.24,0.13-0.52,0.3-0.84c0.3-0.57,1.12-0.86,2.47-0.86h6.78c1.86,0,3.67,0.68,5.42,2.03C81.58,3.06,82.29,3.95,82.84,5.07
                            M77.17,10.91c0.61-0.46,0.92-1.16,0.92-2.09c0-0.93-0.31-1.63-0.92-2.11c-0.61-0.48-1.25-0.71-1.9-0.71H71.3v5.61h3.99
                            C75.93,11.6,76.55,11.37,77.17,10.91"/>
                        </svg>
                    </router-link>
                </div>
                <div class="navigation">
                    <div @click="openBuscar" :class="{active: viewBuscar}" class="search_trigger reveal">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"/></svg>
                        </span>
                    </div>
                    <nav :class="{reveal: menuResponsive}">
                        <div>
                            <router-link to="/productos">Productos</router-link>
                        </div>
                        <div>
                            <router-link to="/artistas">Artistas</router-link>
                        </div>
                        <div>
                            <router-link to="/contacto">Contacto</router-link>
                        </div>
                        <div>
                            <router-link v-if="!$store.state.user" to="/login">Login</router-link>
                        </div>
                        <div>
                            <router-link v-if="$store.state.user" to="/cuenta">Mi cuenta</router-link>
                        </div>
                        <div >
                            <a v-if="$store.state.user" href="javascript:void(0)" class="logout_btn" @click="$parent.logout()">Logout</a>
                        </div>
                    </nav>
                    <div class="cart_btn" @click="stateCart=true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.665 9.068l-3.665-1.66v14l3.665 2.592 14.335-2.155v-14.845l-14.335 2.068zm-1.665 1.441l1 .453v10.118l-1-.707v-9.864zm14 9.615l-11 1.653v-10.881l11-1.587v10.815zm-2-15.833l-.001 1.749c0 .246-.18.455-.423.492-.303.045-.576-.19-.576-.495v-1.746c.001-.691-.231-1.304-.653-1.726-.368-.37-.847-.565-1.384-.565-1.547 0-2.96 1.558-2.963 3.268v1.681c0 .247-.181.457-.425.494-.302.046-.575-.189-.575-.494l.001-1.683c.004-2.261 1.866-4.266 3.962-4.266 1.717 0 3.039 1.387 3.037 3.291zm-9.999 2.209v-2.235c.004-2.26 1.866-4.265 3.962-4.265.492 0 .944.125 1.35.332-.423.17-.822.4-1.188.683l-.162-.015c-1.547 0-2.961 1.558-2.963 3.268v2.232c0 .248-.182.458-.427.494-.3.045-.572-.187-.572-.494z"/></svg>
                        <div class="baloon" v-if="carritoObjetos">{{carritoObjetos}}</div>
                    </div>
                </div>
                <div class="menu_trigger" :class="{close_trigger:menuResponsive}" @click="menuResponsive=!menuResponsive">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
        <div class="collapsible_wrapper collapsible_cart right" :class="{reveal: stateCart}">
            <div class="collapsible_container">
                <div class="collapsible_heading">
                    <h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6.665 9.068l-3.665-1.66v14l3.665 2.592 14.335-2.155v-14.845l-14.335 2.068zm-1.665 1.441l1 .453v10.118l-1-.707v-9.864zm14 9.615l-11 1.653v-10.881l11-1.587v10.815zm-2-15.833l-.001 1.749c0 .246-.18.455-.423.492-.303.045-.576-.19-.576-.495v-1.746c.001-.691-.231-1.304-.653-1.726-.368-.37-.847-.565-1.384-.565-1.547 0-2.96 1.558-2.963 3.268v1.681c0 .247-.181.457-.425.494-.302.046-.575-.189-.575-.494l.001-1.683c.004-2.261 1.866-4.266 3.962-4.266 1.717 0 3.039 1.387 3.037 3.291zm-9.999 2.209v-2.235c.004-2.26 1.866-4.265 3.962-4.265.492 0 .944.125 1.35.332-.423.17-.822.4-1.188.683l-.162-.015c-1.547 0-2.961 1.558-2.963 3.268v2.232c0 .248-.182.458-.427.494-.3.045-.572-.187-.572-.494z"/></svg>Carrito
                    </h2>
                    <div class="close_btn right" @click="stateCart=false"></div>
                </div>
                <div class="cart_list">
                    <div class="cart_list_item" v-for="(cart, index) in carrito" :key="index">
                        <router-link :to="`/productos/${cart.producto.url}`" class="product_image" :style="{'background-image': `url('${cart.producto.img}')`}"></router-link>
                        <div class="product_data">
                            <div class="product_name">
                                <router-link :to="`/productos/${cart.producto.url}`">{{cart.producto.name}}</router-link>
                                <span class="delete_from_cart_btn" @click="delProduct(cart.producto.id, cart.producto.opcion)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                                </span>
                            </div>
                            <p class="product_extra_info"
                                v-for="(opcione, index) in cart.producto.opcion"
                                :key="index"
                            >{{cart.producto.options[index].find(el => el.id == opcione).text}}</p>
                            <div class="product_price_details" :key="cart.cantidad">
                                <div class="product_quantity">
                                    <span class="product_quantity_minus" @click="resCantidad(cart.producto.id)">-</span>
                                    <span class="product_quantity_number">{{cart.cantidad}}</span>
                                    <span class="product_quantity_plus" @click="sumCantidad(cart.producto.id)">+</span>
                                </div>
                                <div class="product_price">
                                    {{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(cart.producto.price * cart.cantidad)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="checkout_block">
                    <div class="ammount_line">
                        <span>Total</span>
                        <span>{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(getTotalCarrito())}}</span>
                    </div>
                    <router-link :to="`/checkout`" class="btn small wide center grey mt10 mb10 checkout_btn" :class="{disabled: !carritoObjetos}">Realizar pedido</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Header',
    data: function(){
        return {
            stateCart: false,
            viewBuscar: false,
            busqueda: '',
            resultBusqueda: {
                productos: [],
                artistas: [],
                categorias: []
            },
            showRelated: false,
            menuResponsive: false,
        }
    },
    methods: {
        delProduct: function(id, opcion){
            this.$store.commit('deleteProduct', id, opcion);
        },
        sumCantidad: function(id){
            this.$store.commit('addQuantity', id);
        },
        resCantidad: function(id){
            this.$store.commit('removeQuantity', id);
        },
        getTotalCarrito: function(){
            let precio = 0;
            this.$store.state.cart.forEach((x) => {
                precio += x.producto.price * x.cantidad;
            });
            return precio;
        },
        openBuscar: function(){
            this.viewBuscar = !this.viewBuscar;
            if(this.viewBuscar)
                document.querySelector('#bsqd').focus();
        },
        focusOut: function(){
            setTimeout(() => {this.showRelated=false}, 100);
        },
    },
    computed: {
        carrito() {
            return this.$store.getters.getCart;
        },
        carritoObjetos(){
            let productos = 0;
            this.$store.getters.getCart.forEach((x) => {
                productos += parseInt(x.cantidad);
            });
            return productos;
        }
    },
    watch:{
        carritoObjetos: function(){
            if(this.carritoObjetos == 0 || this.$route.name == 'Checkout')
                this.stateCart = false;
            else
                this.stateCart = true;
        },
        busqueda: function(newval, oldval){
            if(newval != ''){
                axios
                    .get(process.env.VUE_APP_URL+'products', {
                        params: {
                            search: this.busqueda
                        }
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.resultBusqueda.productos = rs.data;
                        }
                    })
                    .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
                axios
                    .get(process.env.VUE_APP_URL+'artists', {
                        params: {
                            search: this.busqueda
                        }
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.resultBusqueda.artistas = rs.data;
                        }
                    })
                    .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
                axios
                    .get(process.env.VUE_APP_URL+'categories', {
                        params: {
                            search: this.busqueda
                        }
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.resultBusqueda.categorias = rs.data;
                        }
                    })
                    .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
            }
            else
                this.resultBusqueda = [];
        },
        $route: function(to, from){
            //CARRITO
            this.stateCart = false;
            this.menuResponsive = false;

            //MENU RESPONSIVE
            this.menuResponsive = false;
        }
    }
}
</script>