import axios from 'axios'

export const reloadCart = (state) => {
    const data = [];
    for(let i=0; i < state.cart.length; i++){
        data.push({"id": state.cart[i].producto.id, "cantidad": state.cart[i].cantidad});
    }
    axios.post(`${process.env.VUE_APP_URL}reload_cart`, {cart: data})
    .then(response => {
        let rs = response.data;
        if(state.cart.length == 0){
            return;
        }
        if(rs.state == 'OK')
            state.cart = rs.data;
        else{
            state.cart = [];
        }
        setCartToLocalStorage(state.cart);

    })
    .catch(error => {
        state.cart = [];
        setCartToLocalStorage(state.cart);
    });
}

export const addQuantity = (state, id) => {
    const prodindex = state.cart.findIndex((x) => x.producto.id == id);
    const prod = state.cart[prodindex];
    if(prod.producto.stock == null || prod.cantidad < prod.producto.stock){
        state.cart[prodindex].cantidad += 1;
    }
    setCartToLocalStorage(state.cart);
}

export const removeQuantity = (state, id) => {
    let index = state.cart.findIndex((x) => x.producto.id == id);
    state.cart[index].cantidad -= 1;
    if(state.cart[index].cantidad <= 0)
        state.cart.splice(index, 1);
    setCartToLocalStorage(state.cart);
}

export const deleteProduct = (state, id, opcion) => {
    state.cart.splice(state.cart.findIndex((x) => x.producto.id == id && x.producto.opcion === opcion), 1);
    setCartToLocalStorage(state.cart);
}

export const addProduct = (state, data) => {
    const fields = [
        "id", "name", "url", "img", "price", "need_dni", "stock", "has_envio", "discount"
    ];
    const producto = data.producto;
    let cantidad = parseInt(data.cantidad);
    let index = state.cart.findIndex((x) => x.producto.id == producto.id);

    if(index >= 0){
        if(producto.stock != null && state.cart[index].cantidad + cantidad > producto.stock){
            state.cart[index].cantidad = producto.stock;
        }else{
            state.cart[index].cantidad += cantidad;
        }
    }
    else{
        if(producto.stock != null && cantidad > producto.stock) cantidad = producto.stock;
        const p = Object.fromEntries(fields.map(key => [key, producto[key]]));
        state.cart.push({
            cantidad: cantidad,
            producto: p
        });
    }
    setCartToLocalStorage(state.cart);
}

export const resetCart = (state) => {
    state.cart = [];
    setCartToLocalStorage(state.cart);

}

const setCartToLocalStorage = (cart) => localStorage.setItem('cart', JSON.stringify(cart));
