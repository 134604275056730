<template>
  <div class="main_wrapper">
    <div class="disclaimer" v-if="error || opcion">
      <div class="translucid_bg" @click="error=opcion=null">
        <div class="disclaimer_wrapper">
          <div class="disclaimer_block">
            <div class="disclaimer_content">

              <h2 class="mb30" v-if="error">{{error}}</h2>
              <h2 class="mb30" v-if="opcion">{{opcion[0]}}</h2>
              <div class="disclaimer_cta" v-if="error">
                <div class="enter_btn" @click="error=opcion=null">Entendido</div>
              </div>
              <div class="grid grid_pad" v-if="opcion">
                <div class="grid_col grid_col_1_2">
                  <div class="grid_col_box">
                    <div class="btn med graphic_right wide center green" @click="opcion[1]">Si<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg></div>
                  </div>
                </div>
                <div class="grid_col grid_col_1_2">
                  <div class="grid_col_box">
                    <div class="btn med graphic_right wide center red" @click="opcion[2]">No<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="init_message" v-if="!$store.state.cookies">
      <div class="message_content">
        <p>La verdad que sentimos darte la paliza con la Cookies, a nosotros nos gustan más las bananas. Nosotros No comercializamos tus datos, pero nos obligan a darte el coñazo y anunciarte todo esto, tienes que aprobar con un click que aceptas el uso de las cookies que tenemos en nuestra web para una mejor navegación y seguridad para tí. <router-link to="/legal" title="Términos legales">Más información</router-link></p>
        <div class="btn med white" @click="aceptaCookies">Acepto</div>
      </div>
    </div>

    <Header />
    <div class="content_wrapper" :class="this.$route.name">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import Header from '@/components/Header'
import Footer from './components/Footer.vue'

export default {
  components:{
    Header,
    Footer,
  },
  data: function(){
    return{
      error: null,
      opcion: null
    }
  },
  methods:{
    logout: function(){
      axios
        .post(process.env.VUE_APP_URL+'logout')
        .then(response => {
          let rs = response.data;
          if(rs.state == 'OK'){
            window.location.reload();
          }
        })
        this.$store.commit('logout');
    },
    aceptaCookies: function(){
      this.$store.commit('aceptCookies');
    }
  },
  beforeRouteUpdate  (to, from, next) {
      console.log('cambiando'+to);
      next();
	}
}
</script>