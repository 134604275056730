import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueGtag from "vue-gtag"

import store from "@/store/store";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(function (config) {
    if (localStorage.getItem('user') && !config.headers['Api-Token']) {
        config.headers['Api-Token'] = localStorage.getItem('user');
    }
    return config;
});

Vue.config.productionTip = false
if (process.env.VUE_APP_GTAG) {
    Vue.use(VueGtag, {
        config: { id: "G-TEDBTZ176T" }
    }, router);
}

new Vue({
    router,
    store: store,
    beforeCreate() {
		this.$store.commit('initialStore');
	},
    render: function(h) { return h(App) }
}).$mount('.main_wrapper')


import '@/assets/css/style.css'